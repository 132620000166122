@use '@angular/material' as mat;
@include mat.core();

$geotherm-primary-palette: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #f44336,
  600: #e53935,
  700: #dd3333,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (),
);

$geotherm-accent-palette: (
  50: #fff,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #f44336,
  600: #e53935,
  700: #dd3333,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: #dd3333,
    100: #dd3333,
    200: #dd3333,
    300: #dd3333,
    400: #dd3333,
    500: #dd3333,
    600: #dd3333,
    700: #dd3333,
    800: #dd3333,
    900: #dd3333,
    A100: #dd3333,
    A200: #dd3333,
    A400: #dd3333,
    A700: #dd3333,
  ),
);

$geotherm-red:#DD3333;

$geotherm-theme-primary: mat.define-palette($geotherm-primary-palette, 700);
$geotherm-theme-accent: mat.define-palette($geotherm-accent-palette, 50);
$geotherm-theme-warn: mat.define-palette(mat.$red-palette, 500);

$geotherm-typography: mat.define-typography-config(
  $font-family: Roboto,
  $headline-5: mat.define-typography-level(24px, 32px, 500, Roboto, 0.25px),
  $headline-6: mat.define-typography-level(20px, 32px, 500, Roboto, 0.25px),
  $subtitle-1: mat.define-typography-level(16px, 20px, 400, Roboto, 0.25px),
  $subtitle-2: mat.define-typography-level(14px, 20px, 400, Roboto, 0.25px),
);

$geotherm-theme: mat.define-light-theme(
  (
    color: (
      primary: $geotherm-theme-primary,
      accent: $geotherm-theme-accent,
      warn: $geotherm-theme-warn,
    ),
    typography: $geotherm-typography,
    density: 0,
  )
);
// Apply the theme to Angular Material components
@include mat.all-component-themes($geotherm-theme);
@include mat.typography-hierarchy($geotherm-typography);
