@use './geotherm-theme' as geo;
.c-primary {
  color: var(--brand-brand-primary, #d33);
}
.c-success {
  color: green;
}
.c-black1 {
  color: var(--black-black-600, rgba(0, 0, 0, 0.6));
}
.shade-white {
  background: var(--shades-white, #fff);
}
.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
  flex-grow: 0 !important;
}

.mdc-tab__text-label {
  letter-spacing: 0.6px;
}

.mat-input-border {
  border: 1px solid var(--black-018, rgba(0, 0, 0, 0.18));
}
.mat-focused {
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing,
  .mdc-notched-outline__leading {
    border-color: var(--mdc-outlined-text-field-outline-color) !important;
  }
}

.color-black {
  color: var(--black-black-900, rgba(0, 0, 0, 0.88));
}
.font-wt-500 {
  font-weight: 500;
}
.bwhite {
  background-color: #ffffff !important;
}
.bg-bulk {
  background: var(--blue-blue-500, rgba(59, 134, 198, 0.5));
}
.bg-tube {
  background: var(--brand-brand-200, rgba(221, 51, 51, 0.2));
}
.bg-reuse {
  background: var(--Yellow-Yellow, #ffc107);
}

// overriding + addition of property to mat core classes
.mat-mdc-raised-button.mat-mdc-button-base {
  padding: 0.875rem;
}

.mdc-text-field--outlined .mdc-notched-outline {
  height: 3rem;
  border-radius: 0.5rem;
  // border: 1px solid var(--black-018, rgba(0, 0, 0, 0.18));
}

::ng-deep.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

::ng-deep.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0px !important;
  margin-bottom: 0.75rem;
}

::ng-deep.mat-mdc-form-field-hint-wrapper .mat-mdc-form-field-hint {
  padding: 0rem;
  margin-bottom: 0.75rem;
}

.mat-mdc-dialog-container .mdc-dialog__title {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0 0 1rem 1.25rem;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 8px !important;
}

.mat-subtitle-2 {
  color: var(--black-06, rgba(0, 0, 0, 0.6));
}

.mat-mdc-dialog-actions {
  padding: 1rem 1.5rem;
}

.mdc-list-item {
  padding-left: 0.5rem !important;
  height: 2.25rem !important;
}

.close-button {
  float: right;
  margin: 0.5rem;
}

// Setting global style for application
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 100%;
}

body {
  background-color: #f5f5f5 !important;
}

.mb-8 {
  margin-bottom: 0.5rem;
}
.mt-8 {
  margin-top: 0.5rem;
}

.mt-12 {
  margin-top: 0.75rem;
}

.mt-16 {
  margin-top: 1rem;
}

.mt-20 {
  margin-top: 1.25rem;
}

.mt-32 {
  margin-top: 2rem;
}

.mr-8 {
  margin-right: 0.5rem;
}

.mr-12 {
  margin-right: 0.75rem;
}

.mr-16 {
  margin-right: 1rem;
}

.mr-20 {
  margin-right: 1.25rem;
}

.mr-8 {
  margin-right: 0.5rem;
}

.mr-12 {
  margin-right: 0.75rem;
}

.mr-20 {
  margin-right: 1.25rem;
}

.ml-8 {
  margin-left: 0.5rem;
}

.ml-12 {
  margin-left: 0.75rem;
}

.ml-16 {
  margin-left: 1rem;
}

.ml-20 {
  margin-left: 1.25rem;
}
.pb-8 {
  padding-bottom: 0.5rem;
}
.pt-8 {
  padding-top: 0.5rem;
}

.pt-16 {
  padding-top: 1rem;
}
.pb-16 {
  padding-bottom: 1rem;
}
.pb-20 {
  padding-bottom: 1.25rem;
}
.pb-24 {
  padding-bottom: 1.5rem;
}

.pt-8 {
  padding-top: 0.5rem;
}

.pt-12 {
  padding-top: 0.75rem;
}

.pt-14 {
  padding-top: 0.875rem;
}

.pt-20 {
  padding-top: 1.25rem;
}

.pl-16 {
  padding-left: 1rem;
}

.pr-16 {
  padding-right: 1rem;
}

.pr-32 {
  padding-right: 2rem;
}
// Set Global Scroll bar

/* width */
::-webkit-scrollbar {
  width: 0.5rem;
  overflow: auto;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.5rem grey;
  border-radius: 0.5rem;
  background-color: rgb(201, 199, 199);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 0.5rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: rgb(74, 73, 73);
}

// Set Table Scroll bar
.dash-container::-webkit-scrollbar {
  height: 0.5rem;
  border-radius: 0.5rem;
}

.dash-container::-webkit-scrollbar-track {
  height: 0.5rem;
  box-shadow: inset 0 0 0.5rem grey;
  border-radius: 0.5rem;
  background-color: rgb(201, 199, 199);
}

.dash-container::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 0.5rem; // Put your color
}

.dash-container::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: rgb(74, 73, 73); // Put your color
}

.dash-container::-webkit-scrollbar-button,
.dash-container::-webkit-scrollbar-track,
.dash-container::-webkit-scrollbar-track-piece,
.dash-container::-webkit-scrollbar-corner,
.dash-container::-webkit-resizer {
  display: none;
}

//Custome Classes

.section-1200 {
  max-width: 1200px;
  margin: 0 auto;
}

.gcontainer {
  min-height: 90.5vh;
  padding: 1rem 2.5rem;
}

.bsmokewhite {
  background-color: whitesmoke;
}

.truncate-label {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.truncate-label-2line {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.breakWord {
  word-wrap: break-word;
  display: inline;
  width: 100%;
}

.dash-container {
  overflow-y: auto;
}

.text-area > .mdc-text-field--outlined .mdc-notched-outline {
  height: -webkit-fill-available;
}

.bold-title {
  font-weight: 700;
  color: #000000;
}

// Importing

.mat-mdc-raised-button.mat-unthemed {
  color: #dd3333 !important;
  border: 1px solid #dd3333;
}

.alert-icon {
  background-image: url(./assets/icons/Alert.svg);
  position: relative;
}

.info-icon {
  background-image: url(./assets/icons/info-icon.svg);
  vertical-align: -webkit-baseline-middle !important;
}

.warning-title {
  color: #b92a2a !important;
}

.tab-container {
  margin-top: 1rem;
}

.default-snackbar {
  background: #333333;
}

.red-snackbar {
  background: #dd3333;
}

// .mat-date-input {
//   padding-left: 14px !important;
// }

// ::ng-deep.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
//   color: #dd3333 !important;
// }

// @media (min-width: 840px) and (max-width: 900px) {

//     .mat-body, .mat-typography  {
//       font-size: 12px !important;
//     }

// }

.online-type .mat-mdc-text-field-wrapper {
  height: 48px !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: inherit !important;
}

.mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-icon-suffix {
  padding-bottom: 8px !important;
}

.online-type .mat-mdc-form-field-flex {
  margin-top: -2px !important;
}

.dashboard-menu .mat-mdc-menu-item {
  min-height: 32px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.dashboard-menu:active {
  color: #d33;
  fill: #d33;
}

.dashboard-menu svg:hover {
  fill: #d33;
}

.dashboard-menu .mat-mdc-menu-item :hover {
  color: #d33;
  fill: #d33;
}

.online-type .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 23px !important;
}

.mdc-menu-surface.mat-mdc-select-panel {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #424242);
}

.mdc-text-field__input {
  /*border-radius: 0.5rem;*/
}

.btn-action {
  border-radius: 4px;
  background: var(--custom-primary, #d33);
  color: #ffffff;
  float: right;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  box-sizing: border-box;
  background: #dd3333;
  border: 1px solid #dd3333;
  height: 36px;
  padding: 0px 16px;
}

.btn-action.mat-mdc-button:not(:disabled) {
  color: #ffffff;
}

.online-type .mat-mdc-select-arrow-wrapper {
  height: 26px !important;
}

.header-title {
  border-radius: 8px 8px 0px 0px;
  background: var(--light-grey, #f9f9f9);
  color: var(--shades-black, rgba(0, 0, 0, 0.88));
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.25px;
}

.font-size-12 {
  font-size: 12px;
  /* font-weight: bold; */
}

.font-size-14 {
  font-size: 14px;
}

.action-menu {
  margin-right: 8px;
}

.status-dropdown .mat-mdc-select-trigger {
  padding-top: 4px !important;
  margin-left: 0px !important;
}

.tab-container .mdc-tab-indicator {
  padding-left: 0px !important;
}

// .mat-elevation-z8,
// .mat-mdc-elevation-specific.mat-elevation-z8 {
//   box-shadow: none !important;
// }

.text-padding {
  padding: 0px 24px;
}

.mdc-tab {
  padding: 0px 10px !important;
  // margin-right: 20px !important;
  border: 1px solid var(--black-black-100, rgba(0, 0, 0, 0.1)) !important;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
}

.company-card {
  max-width: 1200px;
}

.native-bulk-card.mat-mdc-card {
  box-shadow: none !important;
}

.mat-mdc-card-content {
  padding: 1rem;
}

// .mat-mdc-select-arrow svg {
//   visibility: hidden;
// }
.mat-mdc-select-arrow {
  // background-image: url(./assets/icons/keyboard_arrow_down.svg);
  background-position: center;
  height: 10px !important;
  /* padding-right: 30px; */
  background-repeat: no-repeat;
}
.drop-down {
  margin-bottom: -6px;
  color: #000000;
}
.search-text {
  color: var(--black-black-900, rgba(0, 0, 0, 0.88));
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.drop-down {
  margin-bottom: -6px;
  color: #000000;
}

/* removing arrow for number input box */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.radio-width {
  margin: 0px 8px 0px 0px;
}
.mat-mdc-paginator {
  background: none;
}

.mat-mdc-select-value {
  overflow: visible !important;
}
.notification-menu {
  margin-top: 11px !important;
  max-width: 450px !important;
  min-width: 467px !important;
  padding: 11px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  border-radius: 0px 0px 8px 8px !important;
  background: var(--black-black-900, rgba(0, 0, 0, 0.88)) !important;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2) !important;
}

.mat-mdc-card {
  border-radius: 0.5rem !important;
}

.mat-mdc-menu-content {
  padding: 0.5rem !important;
}

.mat-mdc-menu-panel {
  min-width: 216px !important;
  max-width: 400px !important;
  border-radius: 8px !important;
}

.mat-mdc-form-field {
  width: 100%;
  max-width: 100%;
}

.mat-expansion-panel-header:not([aria-disabled='true']) {
  height: 8.5rem;
}

.required {
  color: var(--brand-brand-primary, #d33);
  line-height: 1rem;
}

.list-style-type-none {
  list-style-type: none;
  padding: 0px;
}

.text-align {
  text-align: center;
}

/* Overridden Styles */

/* General Setting*/
.margin-bottom-20 {
  margin-bottom: 20px;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.clearfix {
  clear: both;
}

/* Custom Classes */

.section-header {
  padding: 0px 24px;
}
.heading {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.88);
  margin-top: 24px;
  margin-bottom: 20px;
}

.remove-icon {
  padding: 24px;
  margin-top: 27px;
  background-image: url(./assets/icons/Delete1.svg);
}

.add-icon {
  background-image: url(./assets/icons/Add.svg);
}

.alink {
  color: gray;
  vertical-align: top;
}

.cursor {
  cursor: pointer;
}

.max-height-70 {
  max-height: 70vh;
}

.testing {
  font-family: 'Roboto';
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #b92a2a;
  cursor: pointer;
  margin-left: -6px;
}

/* Stepper Overriden */

.mat-stepper-vertical,
.mat-stepper-horizontal {
  display: contents !important;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: green;
}

.mat-horizontal-stepper-header-container {
  width: 70%;
}

.stepper-buttons {
  margin-top: 25px;
}

.button-primary {
  color: white !important;
  border: 1px solid var(--brand-brand-primary, #d33) !important;
  background: var(--brand-brand-primary, #d33) !important;
}

.button-primary:disabled {
  background: var(--brand-brand-primary, rgb(206, 119, 119)) !important;
}

.test-mar-top {
  margin-top: 27px;
}

.mat-step-header .mat-step-label.mat-step-label-active {
  font-size: 14px;
}

// .cdk-overlay-pane {
//   box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 14px 0px, rgba(0, 0, 0, 0.14) 0px 9px 10px 0px,
//     rgba(0, 0, 0, 0.2) 0px 5px 5px 0px;
// }

.success-popup-title-icon {
  color: #198754;
  width: 24px;
  height: 24px;
  top: 44px;
  left: 48px;
}

.popup-icon {
  display: inline-block;
  margin-top: 4px;
  vertical-align: middle;
}
.header-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.88);
  /* text-overflow: ellipsis;
  overflow: hidden; */
}

.header-right {
  position: absolute;
  top: 0px;
  margin-right: auto;
  right: -12px;
  display: flex;
  flex-direction: row;
}

.sample-container {
  background-color: #fff;
  margin-right: 12px;
  border-radius: 8px;
  border: 1px solid var(--black-black-200, rgba(0, 0, 0, 0.2));
  padding: 12px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  height: 96px;
}

.sample-summary {
  display: flex;
  justify-content: space-around;
  /* margin-top: 8px; */
}

.soil-type {
  color: var(--shades-black, rgba(0, 0, 0, 0.88));
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.inner-summary {
  :first-child {
    color: geo.$geotherm-red;
    font-weight: bold;
    font-size: 24px;
  }

  font-size: 12px;
  text-align: center;
  padding-right: 10px;
}

.pad-12 {
  padding: 12px;
}

.card-count {
  color: geo.$geotherm-red;
  font-size: 24px;
  font-weight: bold;
}

.font-12 {
  font-size: 12px;
  font-weight: 400;
}

.td-align {
  text-align: center;
}

.menu-text {
  color: var(--black-black-600, rgba(0, 0, 0, 0.6));
  font-size: 14px;
  font-family: Roboto;
  line-height: 22px;
  letter-spacing: 0.1px;
  padding: 8px;
}

.ellips {
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mat-table {
  overflow-y: hidden;
}

.mat-table td {
  height: 48px;
}
.pad-11 {
  padding: 0.6875rem;
}

.my-custom-tooltip .mdc-tooltip__surface {
  max-width: 800px;
  max-height: 500px;
}

.assign-err-text {
  margin-left: -18px;
  margin-top: -11px;
}
.mar-text-align {
  margin-left: -8px;
  margin-top: -10px;
}
.mar-top-16 {
  margin-top: -16px;
}

.text-sample {
  display: inline-block;
  margin-left: -6px;
}
.star-mar-top {
  margin-top: -2px;
}
.status-err-text {
  margin-left: -18px;
  margin-top: -10px;
}

.search-section .mdc-text-field--outlined .mdc-notched-outline {
  background: #fff !important;
  z-index: 0 !important;
}
.mdc-menu-surface.mat-mdc-select-panel {
  padding-left: 5px !important;
}

input:focus,
select:focus,
button:focus {
  outline: none !important;
}

.textarea-popup {
  resize: vertical;
  max-height: 300px;
  min-height: 30px;
}

.mat-step-icon .mat-icon {
  color: #ffffff;
}

.mat-step-header .mat-step-icon {
  color: #ffffff;
}
.bulk-section .mdc-text-field--outlined .mdc-notched-outline {
  background: #fff;
  z-index: 0;
}
.mat-error-nativesoil {
  margin-left: -16px;
}
.mat-mdc-select-arrow svg {
  display: none;
}
.mat-mdc-select-arrow {
  border: none;
  background-image: url('assets/icons/keyboard_arrow_down.svg');
}
.disabled-wrapper {
  .mat-mdc-select-arrow {
    background-image: url('assets/icons/Chevron-disabled.svg');
  }
}

.dashboard-pagination .mdc-text-field--outlined .mdc-notched-outline {
  height: 44px !important;
  padding: 3px;
}

.dashboard-pagination .mat-mdc-select-arrow {
  border: none;
  background-image: url('assets/icons/Caret.svg');
  display: inline-block;
  margin-top: -3px;
}

/*Review Submission form*/
.mat-typography {
  .review-each-wrapper {
    padding: 20px;
    border-radius: 5px;
    h4 {
      font-size: 14px;
      margin-bottom: 3px;
      &.bold {
        font-weight: 700;
      }
    }
    .block-wrapper {
      h3 {
        font-weight: 500;
      }
    }
    .bd {
      border-right: 1px solid rgba(0, 0, 0, 0.2);
    }
    .btn-wrap {
      text-align: right;
    }
  }
}

.page-card-container {
  max-width: 440px;
  margin: 30px auto 0 auto;
}

.browser-warning {
  padding: 10px;
  background-color: #ffeac2;
  font-weight: 500;
  margin-bottom: 15px;
}
.pre-btn-disabled {
  .mdc-label {
    pointer-events: none;
  }
  .mdc-switch__track::after {
    background: #ccc !important;
  }
}
@media print {
  .no-print {
    display: none !important;
  }
  @page {
    margin: 0.5cm;
    size: landscape;
  }
  body::after {
    content: none !important;
  }
  #header-container {
    background-color: rgba(0, 0, 0, 0.8);
    print-color-adjust: exact;
  }
  .mat-typography {
    .review-sample-wrapper {
      thead {
        display: table-row-group;
      }
      .mat-elevation-z8 {
        .mat-mdc-header-row {
          background-color: #0000000a;
          print-color-adjust: exact;
        }
        .mat-mdc-row {
          border-bottom: #0000001a;
          print-color-adjust: exact;
        }
        .mat-mdc-row:nth-child(even) {
          background-color: #f9f9f9;
          print-color-adjust: exact;
        }
      }
    }
  }

  .bd {
    border-right: none !important;
    .col-sm-6 {
      float: left;
      width: 50% !important;
    }
    .col-sm-5 {
      float: left;
      width: 50% !important;
    }
  }
  .for-print {
    float: left !important;
    width: 50% !important;
  }
  .for-print-clear {
    clear: both;
  }
  .cdk-global-scrollblock {
    position: relative !important;
  }
}

.mat-hint-red {
  color: var(--brand-brand-primary, #d33) !important;
  margin-bottom: 20px;
}

.chipset-warning {
  background-color: rgba(255, 193, 7, 1) !important;
}

.chip-text {
  padding-top: 1px;
}

.chipset-green {
  background-color: rgba(25, 135, 84, 1) !important;
}
.chipset-blue {
  background-color: rgba(59, 134, 198, 1) !important;
}

.chipset-green-icon {
  margin-bottom: 4px;
  margin-right: 4px;
}

.text-white {
  color: rgba(255, 255, 255, 1) !important;
}

mat-chip {
  margin: 0px 5px 0px 5px;
}

.mat-pseudo-checkbox {
  width: 30px !important;
  width: 29px;
}

@media print {
  * {
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
}

.export-print-btn {
  position: relative;
  right: 4px;
}

.mat-expansion-panel-header{
  cursor: default !important;
}
.mat-expansion-indicator{
  cursor: pointer !important;
}